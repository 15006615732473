import ImageListModal from "./../components/ImageListModal.tsx";
import { type ImageListModalProps } from "./../components/ImageListModal.tsx";
import React from "react";
// @ts-ignore
import belak from "./../assets/gallery/belak.png";
// @ts-ignore
import sasik from "./../assets/gallery/sasik.png";
// @ts-ignore
import yosima from "./../assets/gallery/yosima.png";
// @ts-ignore
import stromko from "./../assets/gallery/stromko.png";
// @ts-ignore
import molnar from "./../assets/gallery/molnar.png";

const itemData = [
  {
    img: yosima,
    title: "Yosima",
    link: "https://www.instagram.com/p/DBG-xPBtBNv/",
  },
  {
    img: belak,
    title: "Belák",
    link: "https://www.instagram.com/p/C_jA-WLNjTO/",
  },
  {
    img: molnar,
    title: "Molnár",
    link: "https://www.instagram.com/p/C-aMI5ENt-F/",
  },
  {
    img: sasik,
    title: "Sasik",
    link: "https://www.instagram.com/p/DBGPR-CNjWB/",
  },
  {
    img: stromko,
    title: "Stromko",
    link: "https://www.instagram.com/p/DBRNU5UtzGy/",
  },
];

export const GalleryPage = () => {
  return (
    <div>
      <ImageListModal itemData={itemData} />
    </div>
  );
};
