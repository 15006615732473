import { Grid2, Stack, Typography } from "@mui/material";
// @ts-ignore
import vianocne_kotle from "./../assets/competitions/vianocne_kotle.jpg";
// @ts-ignore
import klubovka from "./../assets/competitions/klubovkaSep.webp";
export function CompetitionsPage() {
  return (
    <div>
      <Stack spacing={4} direction={"row"}>
        <Stack style={{ textAlign: "left" }} spacing={1}>
          <Typography variant="h4">Vianocne Kotle</Typography>
          <Typography variant="subtitle1">21.12.2024</Typography>
          <img
            src={vianocne_kotle}
            alt="vianocne_kotle"
            style={{ width: "350px", height: "350px" }}
          />
          <a href={"https://www.powerlifting-ipf.com"}>Výsledky</a>
        </Stack>
        <Stack style={{ textAlign: "left" }} spacing={1}>
          <Typography variant="h4">Klubovka</Typography>
          <Typography variant="subtitle1">21.9.2024</Typography>
          <img
            src={klubovka}
            alt="klubovka"
            style={{ width: "350px", height: "350px" }}
          />

          <a href={"https://www.powerlifting-ipf.com"}>Výsledky</a>
        </Stack>
      </Stack>
    </div>
  );
}
