import React, { useState } from "react";
import {
  Stack,
  ImageList,
  ImageListItem,
  Modal,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

interface ImageItem {
  img: string;
  title: string;
  link: string;
}

export interface ImageListModalProps {
  itemData: ImageItem[];
}

const ImageListModal: React.FC<ImageListModalProps> = ({ itemData }) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleOpen = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(null);
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex !== null ? (prevIndex + 1) % itemData.length : 0,
    );
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex !== null
        ? (prevIndex - 1 + itemData.length) % itemData.length
        : itemData.length - 1,
    );
  };

  const selectedItem = selectedIndex !== null ? itemData[selectedIndex] : null;

  const width = Math.ceil(itemData.length / 2) * 164;
  const height = Math.ceil(itemData.length / 3) * 164;

  return (
    <div>
      <Stack spacing={2}>
        <ImageList sx={{ width, height }} cols={3} rowHeight={164}>
          {itemData.map((item, index) => (
            <ImageListItem
              key={item.img}
              onClick={() => handleOpen(index)}
              style={{ cursor: "pointer" }}
            >
              <img
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                style={{ width: "100%", height: "100%" }}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              outline: "none",
              maxWidth: "100%",
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {selectedItem && (
              <>
                {/* Close Button */}
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    "&:hover": {
                      bgcolor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <img
                  srcSet={`${selectedItem.img}?w=500&h=500&fit=crop&auto=format&dpr=2 2x`}
                  src={`${selectedItem.img}?w=500&h=500&fit=crop&auto=format`}
                  alt={selectedItem.title}
                  loading="lazy"
                  style={{ maxWidth: "100%", maxHeight: "80%" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <IconButton onClick={handlePrev} aria-label="previous">
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Stack sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ extAlign: "center" }}>
                      {selectedItem.title}
                    </Typography>
                    <a href={selectedItem.link} style={{ color: "black" }}>
                      {selectedItem.link}
                    </a>
                  </Stack>

                  <IconButton onClick={handleNext} aria-label="next">
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </Stack>
    </div>
  );
};

export default ImageListModal;
