import { Typography } from "@mui/material";

const text = `
Slovník piešťanského trojboja schválený Erikom Petrášom:<br/>
- bench press = tlaky<br/>
- deadlift = kotle<br/>
- opasok = kotlák<br/>
- pretekár = kotlár<br/>
- bar jack = pocit kotlování<br/>
- neplatný pokus = vyzulo ho/ju<br/><hr class="dashed">
Slovník piešťanského trojboja schválený Michalom Vavrincom:<br/>
- nevidíš progress = skús equipped<br/>
- kaprál = pôsobivý pretekár<br/>
- kojot = pretekár, ktorý sa snaží, ale nevie<br/>
- hladový = vysoko sústredený pretekár<br/>
- ošklivý = nezastavitelný pretekár<br/>
`;

export function CoachingPage() {
  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "left" }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
