export function EventsPage() {
  return (
    <div>
      <h1>Events</h1>
      <p>
        Here you can find information about upcoming events and competitions.
      </p>
    </div>
  );
}
