// @ts-ignore
import logo from "./../assets/kstk_logo_no_bcg.png";
import { Grid2, Stack, Typography } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

interface MainHeaderProps {
  onClick?: () => void;
  sx?: object;
}

export function MainHeader({ onClick, sx }: MainHeaderProps) {
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        flexWrap: "nowrap",
        alignItems: "center",
        cursor: onClick ? "pointer" : "default",
        ...sx,
      }}
      onClick={onClick} // Attach the onClick handler here
    >
      <img
        src={logo}
        alt="logo"
        style={{ width: "100px", padding: "1rem 0" }}
      />
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <Typography variant="h2" color={"primary"}>
          Powerlifting Piešťany
        </Typography>
        <Typography variant="h6" fontWeight={"bold"}>
          <FitnessCenterIcon
            fontSize={"inherit"}
            sx={{ mr: 2, rotate: "-45deg", transform: "translateY(25%)" }}
          />
          KSTK Piešťany
          <FitnessCenterIcon
            fontSize={"inherit"}
            sx={{
              ml: 1,
              rotate: "135deg",
              transform: "translateY(-25%)",
            }}
          />
        </Typography>
        <Typography variant={"subtitle1"} color={"secondary"}>
          Klub Silového Trojboja a Kulturistiky
        </Typography>
      </Stack>
    </Grid2>
  );
}
