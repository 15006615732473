type ClubOperationText = {
  header: string;
  bullets: string[];
};
export const club_operation_text: ClubOperationText[] = [
  {
    header: "Fungovanie klubu, cieľ",
    bullets: [
      "Chceme byť klub č.1 na SR, vyhrávať a vychovávať Majstrov Slovenska, reprezentantov SR.",
      "Oproti ostatným klubom sa vyznačujeme tímovým duchom, navzájom si pomáhame a radíme v príprave, na treningu, ale aj na súťažiach, berieme to ako tímový šport a takto sa posúvame.",
      "Každý pretekár ma pripravený čo možno najlepší support, coaching/pomoc v príprave, na súťaži, pomoc so súťažným vybavením ako singlet, opasok, atď., tieto zaobstará klub podľa finančných možností tak, aby každý mal základné vybavenie k dispozícii, prípadne si pomôžeme navzájom s požičaním.",
    ],
  },
  {
    header: "Náklady klubu",
    bullets: [
      "Náklady klubu sú spojené najmä s registračným poplatkom nových pretekárov do SAFKST, ako aj ročný členský poplatok do SAFKST za klub ako celok, ale aj zvlášť za každého pretekára.",
      "Ďalšie náklady sú spojené s kúpou vybavenia pre pretekárov (najmä singlety - povinné, opasky, atď.).",
      "Ostatok finančných prostriedkov klub použije na cestovné, prípadne ubytovanie pri súťažiach, ktoré sú viac vzdialené (napr. Bardejov, Sabinov) a reprezentačné, klubové tričká.",
      "Členský poplatok teda je stanovený na 50€ ročne.",
      "V prípade splnenia reprezentačného limitu, alebo víťazstva vo svojej kategórii na MSR má tento pretekár členské na nasledujúcu sezónu bezplatné.",
      "Členské 50€ je potrebné uhradiť na bankové číslo účtu klubu najneskor do konca februára 2023.",
      "Pretekári prihlásení po ukončení jarnej časti sezóny, t.j. od 1.7.2023, majú do konca sezóny polovičné členské, čiže 25€. Ďalšiu sezónu už platia rovnaké pravidlá, ako sú uvedené vyššie.",
    ],
  },
  {
    header: "Príprava",
    bullets: [
      "V rámci Piešťan riešime priestor pre klubové fitko so súťažným vybavením, kde chceme mať kompletné zázemie pre prípravu a spoločné klubové tréningy.",
      "Je potrebné, aby sme trénovali v tréningových skupinách.",
      "Všetko ohľadom nášho klubu má v rámci skupiny v BA na starosti Erik Petráš.",
      "V Pezinku je zodpovedná osoba Jaro Glasnák.",
      "V Liptovskom Mikuláši je to David Tkáč.",
      "V Piešťanoch je to Michal Vavrinec.",
      "Prosím makajme spolu, zlepšujme sa spolu, prídávajme svoje tréningové videá do skupiny a pomáhajme si navzájom v príprave a hlavne s technikou a prevedení cvikov, aby sme mali na súťaži pokusy platné a podľa pravidiel, najmä noví pretekári.",
    ],
  },
  {
    header: "Súťaže",
    bullets: [
      "V skupine je už zdieľaný kalendár, takisto je dostupný na stránke SAFKST.",
      "PRIHLÁSENIE NA SÚŤAŽ PREBIEHA U MŇA NAJNESKOR MESIAC PRED DŇOM KONANIA DANEJ SÚŤAŽE.",
      "dám sem zazdielať FB stránku, kde sú propozície ku všetkým súťažiam, štartovné listiny na súťaže a všetky ostatné informácie.",
      "každý pretekár na každej súťaži bude mať zabezpečený support, niekoho kto mu pomôže počas celej súťaže.",
      "vybavenie na súťaž si môže pretekár riešiť sám, avšak klub má na starosti zabezpečiť mu minimálne potrebné vybavenie na súťaž aby ju mohol absolvovať s čo najlepším možným vybavením.",
    ],
  },
];

export const club_additional_info = `
ČLENSKY POPLATOK <b>50€</b> ZAPLATIT KAZDOROCNE NAJNESKOR DO KONCA JULA!<br/><br>
IBAN: <b>SK2211000000002947141796</b><br/>
VARIABILNY SYMBOL: tvoje rodné číslo<br/>
INFO PRE PRIJÍMATEĽA: tvoje meno a priezvisko<br/><hr>
Link <a href="https://safkst.sk/">SAFKST</a><br/>
Link <a href="https://www.facebook.com/groups/462391223866716">facebook propozicie atd</a><br/>
`;
