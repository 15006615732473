import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";

const categories = ["Squat", "Bench", "Deadlift", "Total"];
const weightClassesMen = [
  "120+",
  "120-",
  "105-",
  "93-",
  "83-",
  "74-",
  "66-",
  "59-",
];
const weightClassesWomen = [
  "84+",
  "84-",
  "72-",
  "63-",
  "57-",
  "52-",
  "47-",
  "43-",
];
const scopeCategories = ["World", "European", "Slovak", "KSTK Piestany"];
const ageCategories = ["Subjunior", "Junior", "Open", "Master"];
const sexCategories = ["Men", "Women"];

export function WeightCategorySBDTable() {
  const [selectedAgeCategory, setSelectedAgeCategory] = useState(
    ageCategories[0],
  );
  const [selectedSexCategory, setSelectedSexCategory] = useState(
    sexCategories[0],
  );
  const [selectedScopeCategory, setSelectedScopeCategory] = useState(
    scopeCategories[0],
  );
  const [weights, setWeights] = useState(weightClassesMen);

  const handleAgeCategoryChange = (event: any) => {
    setSelectedAgeCategory(event.target.value);
  };
  const handleSexCategoryChange = (event: any) => {
    setSelectedSexCategory(event.target.value);
    setWeights(
      event.target.value === "Men" ? weightClassesMen : weightClassesWomen,
    );
  };
  const handleScopeCategoryChange = (event: any) => {
    setSelectedScopeCategory(event.target.value);
  };

  return (
    <div>
      <Stack
        spacing={2}
        direction={"row"}
        sx={{ mb: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <div style={{ margin: "auto auto auto 0" }}>
          <Typography variant={"h4"}>Rekordy</Typography>
        </div>
        <Select
          variant={"outlined"}
          labelId="scope-category-select-label"
          value={selectedScopeCategory}
          onChange={handleScopeCategoryChange}
          sx={{ minWidth: "125px" }}
        >
          {scopeCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Select
          variant={"outlined"}
          labelId="age-category-select-label"
          value={selectedAgeCategory}
          onChange={handleAgeCategoryChange}
          sx={{ minWidth: "125px" }}
        >
          {ageCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Select
          variant={"outlined"}
          labelId="age-category-select-label"
          value={selectedSexCategory}
          onChange={handleSexCategoryChange}
          sx={{ minWidth: "125px" }}
        >
          {sexCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Weight Class</TableCell>
              {categories.map((category) => (
                <TableCell key={category} align="center">
                  {category}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {weights.map((weight) => (
              <TableRow key={weight}>
                <TableCell component="th" scope="row">
                  {weight}
                </TableCell>
                {categories.map((category) => (
                  <TableCell key={category + weight} align="center">
                    {/* Replace with data or input as needed */}-
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
