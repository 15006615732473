import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import InfoIcon from "@mui/icons-material/Info";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CalculateIcon from "@mui/icons-material/Calculate";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import MenuIcon from "@mui/icons-material/Menu";

import { useNavigate } from "react-router-dom";

function MainNavigation() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const labels = [
    { key: "", translation: "Domov" },
    { key: "About Us", translation: "Fungovanie klubu" },
    { key: "Gallery", translation: "Galéria" },
    { key: "Records", translation: "Rekordy" },
    { key: "Competitions", translation: "Súťaže" },
    { key: "Calculator", translation: "Kalkulačka" },
  ];

  const isMobile = window.innerWidth < 600;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (key: any) => {
    if (key) navigate(`/${key.toLowerCase().replace(" ", "-")}`);
    setAnchorEl(null);
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        backgroundColor: "gray",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
      }}
      elevation={3}
    >
      {isMobile ? (
        <>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          §
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleMenuClose(null)}
          >
            {labels.map((label, index) => (
              <MenuItem key={index} onClick={() => handleMenuClose(label.key)}>
                {label.translation}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            navigate(
              `/${labels[newValue].key.toLowerCase().replace(" ", "-")}`,
            );
            setValue(newValue);
          }}
          showLabels
        >
          {labels.map((label, index) => (
            <BottomNavigationAction
              key={index}
              label={label.translation}
              icon={
                index === 0 ? (
                  <HomeIcon />
                ) : index === 1 ? (
                  <InfoIcon />
                ) : index === 2 ? (
                  <PhotoLibraryIcon />
                ) : index === 3 ? (
                  <MilitaryTechIcon />
                ) : index === 4 ? (
                  <EventIcon />
                ) : (
                  <CalculateIcon />
                )
              }
            />
          ))}
        </BottomNavigation>
      )}
    </Paper>
  );
}

export default MainNavigation;
