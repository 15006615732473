import {
  club_operation_text,
  club_additional_info,
} from "../texts/club_operation.ts";
import { Grid2, List, ListItem, Paper, Typography } from "@mui/material";

export function AboutPage() {
  return (
    <>
      <Grid2
        container
        direction={"row"}
        spacing={3}
        sx={{ mt: 1, flexWrap: "nowrap" }}
      >
        <Grid2 container spacing={3} sx={{ mt: 1 }}>
          {club_operation_text.map((item, index) => (
            <div key={index}>
              <Typography variant="h5" sx={{ textAlign: "left" }}>
                {item.header}
              </Typography>
              <List sx={{ padding: 0 }}>
                {item.bullets.map((text, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: "2px 0", // Reduce vertical padding
                      fontSize: "0.875rem", // Smaller font size
                      display: "list-item", // Standard bullet formatting if needed
                      listStyleType: "none", // Remove bullet points
                    }}
                  >
                    {text}
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </Grid2>
        <Paper elevation={3} sx={{ minWidth: "30%", p: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: club_additional_info }}
          />
        </Paper>
      </Grid2>
    </>
  );
}
