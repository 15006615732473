import { Drawer } from "@mui/material";
import React, { useState } from "react";
// @ts-ignore
import cover from "./../assets/home_cover.png";
// @ts-ignore
import cover_mobile from "./../assets/home_cover_mobile.png";
// @ts-ignore
import logo from "./../assets/kstk_logo_no_bcg.png";

type HomePageProps = {
  isOpen: boolean;
};

export const HomePage: React.FC<HomePageProps> = ({ isOpen }) => {
  const [open, setOpen] = useState(isOpen);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isMobile = window.innerWidth < 600;
  return (
    <>
      <Drawer
        anchor={"top"}
        open={open}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
          },
          position: "relative",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: isMobile ? "50px" : "100px",
            position: "absolute",
            top: 10,
            left: 10,
          }}
        />
        <img
          src={isMobile ? cover_mobile : cover}
          alt="cover"
          style={{ maxHeight: "100%" }}
        />
      </Drawer>
    </>
  );
};
