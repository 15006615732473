import "./App.css";
import {
  Stack,
  Typography,
  Container,
  Grid2,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
// @ts-ignore
import logo from "./assets/kstk_logo_no_bcg.png";
import MainNavigation from "./components/MainNavigation.tsx";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage";
import { NewsPage } from "./pages/NewsPage.tsx";
import { RecordsPage } from "./pages/RecordsPage";
import { GalleryPage } from "./pages/GalleryPage";
import { EventsPage } from "./pages/EventsPage";
import { CompetitionsPage } from "./pages/CompetitionsPage";
import { CoachingPage } from "./pages/CoachingPage";
import { CalculatorPage } from "./pages/CalculatorPage";
import { PlaygroundPage } from "./pages/PlaygroundPage";
import { HomePage } from "./pages/HomePage.tsx";
import { MainHeader } from "./components/MainHeader.tsx";

const AppLayout = () => (
  <>
    <div className="app">
      <header className="App-header">
        <MainHeader />
      </header>
      <Container maxWidth={"xl"}>
        <Stack spacing={1}>
          <MainNavigation />
          <Container sx={{ pt: 2, pb: 12 }} maxWidth={"xl"}>
            {/* Renders the child routes */}
            <Outlet />
          </Container>
        </Stack>
      </Container>
    </div>
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "", element: <HomePage isOpen={true} /> },
      { path: "about-us", element: <AboutPage /> },
      { path: "news", element: <NewsPage /> },
      { path: "events", element: <EventsPage /> },
      { path: "gallery", element: <GalleryPage /> },
      { path: "records", element: <RecordsPage /> },
      { path: "competitions", element: <CompetitionsPage /> },
      { path: "coaching", element: <CoachingPage /> },
      { path: "calculator", element: <CalculatorPage /> },
      { path: "playground", element: <PlaygroundPage /> },
    ],
  },
]);

// Define the custom theme
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#1e1e1e", // Dark grey for the default background
      paper: "#121212", // Slightly lighter for Paper components
    },
    text: {
      primary: "#ffffff", // White text for dark backgrounds
      secondary: "#b0b0b0", // Light grey for secondary text
      disabled: "#666666", // Dark grey for disabled text
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    body1: {
      fontSize: "1rem",
    },
  },
  spacing: 8, // Default spacing unit
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
