import { Album } from "@mui/icons-material";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";

const plates = [
  { weight: 25, count: 2, color: "red", size: "2.5rem" },
  { weight: 20, count: 2, color: "blue", size: "2.25rem" },
  { weight: 15, count: 2, color: "yellow", size: "2rem" },
  { weight: 10, count: 2, color: "green", size: "1.75rem" },
  { weight: 5, count: 2, color: "white", size: "1.5rem" },
  { weight: 2.5, count: 2, color: "#000000", size: "1.25rem" },
  { weight: 1.25, count: 2, color: "silver", size: "1rem" },
  { weight: 0.5, count: 2, color: "gold", size: "0.75rem" },
];
const disciplines = ["", "Squat", "Bench", "Deadlift", "Total"];

export function CalculatorPage() {
  return (
    <>
      <div>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: "600px", margin: "0 auto" }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {disciplines.map((discipline) => (
                  <TableCell
                    key={discipline}
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      padding: "4px",
                      height: "3rem",
                    }}
                  >
                    {discipline ? (
                      <div>
                        <TextField
                          variant="standard"
                          type="number"
                          placeholder={discipline}
                          sx={{
                            width: "56px",
                            textAlign: "center",
                            fontSize: "0.75rem",
                          }}
                          slotProps={{
                            input: {
                              style: {
                                fontSize: "0.75rem",
                                textAlign: "center",
                              },
                            },
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            transform: "translateY(40%)",
                            marginLeft: "1px",
                          }}
                        >
                          kg
                        </span>
                      </div>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: "left" }}
                      >
                        Calculate your total!
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {plates.map((plate) => (
                <TableRow key={plate.weight}>
                  {/* Plate row name */}
                  <TableCell sx={{ padding: "4px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "2.5rem",
                      }}
                    >
                      <Album
                        sx={{
                          color: plate.color,
                          fontSize: plate.size,
                          marginRight: "0.25rem",
                        }}
                      />
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        {plate.weight} kg
                      </Typography>
                    </Box>
                  </TableCell>
                  {/* Squat, Bench, Deadlift, and Total Columns */}
                  <TableCell
                    align="center"
                    sx={{ fontSize: "0.8rem", padding: "4px" }}
                  >
                    {plate.count}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "0.8rem", padding: "4px" }}
                  >
                    {plate.count}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "0.8rem", padding: "4px" }}
                  >
                    {plate.count}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "0.8rem", padding: "4px" }}
                  >
                    {plate.count * 3}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
